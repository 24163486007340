import { useAPIRequest } from '~/composables/api/useAPIRequest'
import type { Box } from '~/types/api/Box'

export async function useBox(boxKey: string) {
  const boxData = await useAPIRequest(`/boxes/detail/${boxKey}`)

  if (!boxData) {
    return null
  }

  return boxData as Box
}
